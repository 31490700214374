import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import "./index.css";
import Root, { loader as rootLoader, action as rootAction } from "./routes/root";
import Lens, { loader as lensLoader } from "./routes/lens";
import About from "./routes/about";
import Contact from "./routes/contact";
import { H } from 'highlight.run';

console.log(process.env.REACT_APP_ENV);

H.init('0dqll9eo', {
  environment: process.env.REACT_APP_ENV,
  enableStrictPrivacy: false,
});


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    action: rootAction,
    children: [
      
    ]
  },
  {
    path: "/lens/:lensId",
    element: <Lens />,
    loader: lensLoader,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  }
  
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);