import React, { useState, useEffect, ChangeEvent } from 'react';
import VideoService from '../services/VideoService';
import IVideoResult from '../types/VideoResult';
import Data from "../mock-questions.json";
import Typed from 'typed.js';
import { Answer } from './Answer';

const VideoList: React.FC = () => {
    const [searchResults, setSearchResults] = useState<IVideoResult>();
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        retreiveVideos();
    }, []);

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setSearch(search);
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            const search = e.target.value;
            setSearch(search);
            retreiveVideos();
        }
    }

    const retreiveVideos = () => {
        console.log("retreiveVideos: " + search)
        if (search === '') {
            return;
        }
        setLoading(true);
        setSearchResults(undefined);
        VideoService.getSearch(search)
            .then((response: any) => {
                setSearchResults(response.data);
                console.log(response.data);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                console.log(e);
            });
    };

    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        
        const value = event.target.value;
        setSearch(value);
        retreiveVideos();
    };

    return (
        <div className="border-gray-500">
            <div className='md:px-40 py-5 mx-6'>
                <label htmlFor="search" className="block text-sm font-medium text-gray-700 py-2">
                Question:
                </label>
                <div className="relative mt-1 flex items-center">
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="h-12 block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={search}
                        onChange={onChangeSearch}
                        onKeyUp={handleKeyPress}
                    />
                    <div className="absolute right-0 flex pr-1.5 align-middle">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path 
                                fillRule="evenodd" 
                                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" 
                                clipRule="evenodd" 
                            />
                        </svg>

                    </div>
                    <div className="input-group-append">
                        <button
                        className="btn btn-outline-secondary hidden"
                        type="button"
                        onClick={retreiveVideos}
                        >
                        Search
                        </button>
                    </div>
                </div>
                <label htmlFor="search" className="block text-sm font-medium text-gray-700 py-2">
                    Sample Questions:
                </label>
                <div className='w-32'>
                    {/* Create dropdown select with list of samples */}
                    <select 
                        className="h-5 block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={selectChange}
                    >
                        {Data.questions.map((item) => (
                            <option key={item} value={item}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>
        {/* Search block */}
            <div className='min-h-screen md:px-40 py-5 mx-6'>
                { loading && loading === true &&
                    <div className="flex items-center justify-center ">
                        <div className="w-24 h-24 border-l-2 border-gray-900 rounded-full animate-spin"></div>
                    </div>
                }
                
                { searchResults &&
                    <div className='pb-10'>
                        <label htmlFor="search" className="block text-sm font-medium text-gray-700">
                        Answer:
                        </label>
                        <Answer searchResults={searchResults}/>
                    </div>
                }

                {/* Video block */}
                { searchResults && searchResults.context && searchResults.context.map((post) => (
                    <div className='flex justify-center'>
                        <div className="max-w-md mx-auto bg-grey-50 rounded-xl shadow-md overflow-hidden md:max-w-4xl" key={post.link}>
                            <div className="md:flex">
                                <div className="md:shrink-0 my-auto">
                                    <a href={post.link}>
                                        <img className="w-full h-96 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg" src={post.thumbnail} alt={post.title} />
                                    </a>
                                </div>
                                <div className="mb-1 p-8">
                                    <a href={post.link}>
                                        <div className="py-4 uppercase tracking-wide text-md text-indigo-500 font-semibold">{post.title}</div>
                                    </a>
                                    <p className="mb-1 text-sm italic"><span className='not-italic'>{post.start_time}:</span> {post.text}...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
      </div>
    );
}

export default VideoList;