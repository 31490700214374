import React, { useState, useEffect, ChangeEvent } from 'react';

type Props = {
    searchResults: any;
}

export const Answer = (props: Props) => {
    return (
        <p className="font-bold">{props.searchResults.response} {props.searchResults.response === "I don't know." ? ' Here are some places to check: ' : ''}</p>
    );
};

// const Answer: React.FC<searchResults:string> = () => {
//     return (
//         <p className="font-bold">{searchResults.response} {searchResults.response === "I don't know." ? ' Here are some places to check: ' : ''}</p>
                
//     )
// }

// export default Answer;