import { Outlet, useLoaderData } from "react-router-dom";
import MyHero from "../components/marketing/MyHero";
import { getLenses, createLens } from "../services/lens";

export async function action() {
    const lens = await createLens();
    return { lens };
}

export async function loader() {
    const lenses = await getLenses();
    return { lenses };
}

export default function Root() {
    // const { lenses } = useLoaderData();
    return (
        <>
        <MyHero />
        <div id="detail">
            <Outlet />
        </div>
        </>
    );
}