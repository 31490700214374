import localforage from "localforage";
import { matchSorter } from "match-sorter";
import sortBy from "sort-by";


export async function getLenses(query) {
  await fakeNetwork(`getlenses:${query}`);
  let lenses = [
    {
      "id": "1",
      "name": "Re:Invent 2022",
      "description": "Re:Invent 2022",
      "createdAt": 1630000000000,
      "updatedAt": 1630000000000,
      "owner": "1",
      "slug": "reinvent-2022"
    }
  ];
  if (!lenses) lenses = [];
  if (query) {
    lenses = matchSorter(lenses, query, { keys: ["first", "last"] });
  }
  return lenses.sort(sortBy("last", "createdAt"));
}

export async function createLens() {
  await fakeNetwork();
  let id = Math.random().toString(36).substring(2, 9);
  let lens = { id, createdAt: Date.now() };
  let lenses = await getLenses();
  lenses.unshift(lens);
  await set(lenses);
  return lens;
}

export async function getLens(id) {
  await fakeNetwork(`lens:${id}`);
  let lenses = [
    {
      "id": "1",
      "name": "Re:Invent 2022",
      "description": "Re:Invent 2022",
      "createdAt": 1630000000000,
      "updatedAt": 1630000000000,
      "owner": "1",
      "slug": "reinvent-2022"
    }
  ];
  let lens = lenses.find(lens => lens.id === id);
  return lens ?? null;
}

export async function updatelens(id, updates) {
  await fakeNetwork();
  let lenses = await localforage.getItem("lenses");
  let lens = lenses.find(lens => lens.id === id);
  if (!lens) throw new Error("No lens found for", id);
  Object.assign(lens, updates);
  await set(lenses);
  return lens;
}

export async function deletelens(id) {
  let lenses = await localforage.getItem("lenses");
  let index = lenses.findIndex(lens => lens.id === id);
  if (index > -1) {
    lenses.splice(index, 1);
    await set(lenses);
    return true;
  }
  return false;
}

function set(lenses) {
  return localforage.setItem("lenses", lenses);
}

// fake a cache so we don't slow down stuff we've already seen
let fakeCache = {};

async function fakeNetwork(key) {
  if (!key) {
    fakeCache = {};
  }

  if (fakeCache[key]) {
    return;
  }

  fakeCache[key] = true;
  return new Promise(res => {
    setTimeout(res, Math.random() * 800);
  });
}