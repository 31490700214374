import http from "../http-common";
import IVideoResult from "../types/VideoResult";

const getSearch = (question: any) => {
    return http.get<IVideoResult>(`/question_answer/aws_reinvent_2022/${question}`);
}

const VideoService = {
    getSearch
};

export default VideoService;