import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="rounded-lg bg-gray-100 text-center lg:text-left mt-4">
            <p className="text-center mx-auto mt-5 max-w-xl italic text-s text-black">
                All content was produced by AWS and is the property of AWS. This site is not affiliated with AWS.
                </p>
            {/* <div className="container p-6 text-gray-800">
                <div className="grid lg:grid-cols-2 gap-4">
                <div className="mb-6 md:mb-0">
                    <h5 className="font-medium mb-2 uppercase">Footer text</h5>

                    <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
                    molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae
                    aliquam voluptatem veniam, est atque cumque eum delectus sint!
                    </p>
                </div>

                <div className="mb-6 md:mb-0">
                    <h5 className="font-medium mb-2 uppercase">Footer text</h5>

                    <p className="mb-4">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
                    molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae
                    aliquam voluptatem veniam, est atque cumque eum delectus sint!
                    </p>
                </div>
                </div> 
            </div>*/}

            <div className="text-center text-gray-700 p-4">
                <p className="text-gray-800">Lensifai</p>
            </div>
        </footer>
    )
}

export default Footer;