import '../App.css';
// import { Search } from './components/Search';
import { useEffect, useState } from 'react';
// import VideoList from './components/VideoList';
import Footer from '../components/Footer';
import VideoList from '../components/VideoList';
import { Form, useLoaderData } from 'react-router-dom';
import { getLens } from '../services/lens';

export async function loader({ params }) {
  const lens = await getLens(params.lensId);
  return { lens };
}

export default function Lens() {
    const lens = useLoaderData();
    return (
        <div className='flex flex-col h-screen'>
        <div className="bg-gradient-to-r from-fuchsia-600 to-purple-600">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="text-center">
                <h2 className="text-lg font-semibold text-white">Use GPT Powered Search</h2>
                <p className="mt-1 text-4xl font-bold tracking-tight bg-gradient-to-r from-slate-50 via-gray-200 to-yellow-200 bg-clip-text text-transparentbg-gradient-to-r from-slate-50 via-gray-200 to-yellow-200 bg-clip-text text-transparent sm:text-5xl lg:text-6xl">Ask AWS Re:Invent!</p>
                <p className="mx-auto mt-5 max-w-xl text-l text-yellow-200">
                We transcribed, indexed, and made searchable all the sessions from AWS Re:Invent 2022 using the <a href='https://www.youtube.com/playlist?list=PL2yQDdvlhXf_hIzmfHCdbcXj2hS52oP9r'>playlist</a> as our lens. Ask us anything!
                </p>
            </div>
            </div>
        </div>
        <VideoList />
        <Footer />
        
        </div>
    );
}

